import styled from "@emotion/styled";

const ContactUsForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding: 40px 100px;
`;

export default ContactUsForm;
