import styled from "@emotion/styled";
import { css } from "@emotion/react";

interface IVariant {
  variant: string;
}

const dynamicStyles = ({ variant }: IVariant) => css`
  ${variant !== ""
    ? `
    padding-bottom: 22px;
    `
    : null}
`;

const InputLabel = styled.label`
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-start;

  ${dynamicStyles}
`;

export default InputLabel;
