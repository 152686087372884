import Input from "@components/Input";
import { validateEmail, validateNickname } from "@helpers/validate";
import { useHttp } from "@hooks/useHttp";
import { _BASE_URL } from "@config/variables";
import { useCallback, useState } from "react";
import ContactUsButton from "./ContactUsButton";
import ContactUsForm from "./ContactUsForm";
import ContactUsInput from "./ContactUsInput";
import ContactUsTitle from "./ContactUsTitle";
import ContactUsTop from "./ContactUsTop";
import ContactUsWrapper from "./ContactUsWrapper";
import DeleteIcon from "./DeleteIcon";
import InputFile from "./InputFile";
import LabelInputFile from "./LabelInputFile";
import MessageArea from "./MessageArea";
import SelectInput from "./SelectInput";
import SelectOption from "./SelectOption";
import UploadImage from "./UploadImage";
import UploadImageWrapper from "./UploadImageWrapper";


const ContactUsComponent = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    theme: "",
    message: "",
    image: "",
  });

  const {request, loading, error} = useHttp()

  const handleChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>): void => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    },
    [formData, setFormData],
  );

  const onImageChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files[0]) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          setFormData({ ...formData, image: e.target.result });
        };
        reader.readAsDataURL(e.target.files[0]);
      }
    },
    [formData, setFormData],
  );

  const handleDeleteImage = useCallback(() => {
    setFormData({ ...formData, image: "" });
  }, [formData, setFormData]);

  const handleSubmit = useCallback(() => {
    request(`${_BASE_URL}/feedback`, 'POST', formData)
    console.log(formData);
  }, [request, formData]);

  return (
    <ContactUsWrapper>
      <ContactUsForm>
        <ContactUsTitle>Contact Us</ContactUsTitle>
        <ContactUsTop>
          <Input
            variant=""
            validate={validateNickname}
            type="text"
            placeholder="Name...*"
            value={formData.name}
            name="name"
            handler={handleChangeInput}
            title=""
            id=""
          />
          <Input
            variant=""
            validate={validateEmail}
            type="email"
            value={formData.email}
            placeholder="Email...*"
            name="email"
            handler={handleChangeInput}
            title=""
            id=""
          />
        </ContactUsTop>
        <SelectInput
          placeholder="Your theme..."
          value={formData.theme}
          name="theme"
          onChange={handleChangeInput}
        >
          <SelectOption value="" disabled selected>
            Select your reason...*
          </SelectOption>
          <SelectOption>Pooot</SelectOption>
          <SelectOption>Qwqw</SelectOption>
        </SelectInput>
        <MessageArea
          placeholder="Message..."
          value={formData.message}
          name="message"
          onChange={handleChangeInput}
        />
        <InputFile type="file" id="input-file" onChange={onImageChange} />
        <LabelInputFile htmlFor="input-file">Enter your file...</LabelInputFile>
        <UploadImageWrapper image={formData.image}>
          <UploadImage src={formData.image} />
          <DeleteIcon onClick={handleDeleteImage}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M18 18L6 6M18 6L6 18" stroke="black" />
            </svg>
          </DeleteIcon>
        </UploadImageWrapper>
        <ContactUsButton
          disabled={formData.name === "" || formData.email === "" || formData.theme === ""}
          onClick={handleSubmit}
        >
          Button
        </ContactUsButton>
      </ContactUsForm>
    </ContactUsWrapper>
  );
};

export default ContactUsComponent;
